/* Scroll bar */
body::-webkit-scrollbar-track,
.ant-table-body::-webkit-scrollbar-track {
  border-radius: 0px !important;
  background-color: transparent !important;
}

body::-webkit-scrollbar,
.ant-table-body::-webkit-scrollbar {
  width: 4px !important;
  background-color: transparent !important;
}

body::-webkit-scrollbar-thumb,
.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 8px !important;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)!important; */
  background-color: lightgray !important;
}
body::-webkit-scrollbar,
.ant-table-body::-webkit-scrollbar {
  width: 4px !important;
  background-color: transparent !important;
}
.ant-table-body {
  overflow-y: auto !important;
}
.ant-table-fixed {
  margin: 4px 0px !important;
}

.ant-table-body::-webkit-scrollbar:horizontal {
  height: 6px;
}
/* container list */
.container-list:-webkit-scrollbar:horizontal {
  height: 6px;
}
.container-list::-webkit-scrollbar-track {
  border-radius: 0px !important;
  background-color: transparent !important;
}

.container-list::-webkit-scrollbar {
  margin: 4px;
  width: 8px !important;
  background-color: transparent !important;
}

.container-list::-webkit-scrollbar-thumb {
  border-radius: 8px !important;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)!important; */
  background-color: var(--gray) !important;
}
