/* Poppins Normal */
@font-face {
  font-family: "Poppins";
  font-weight: normal;
  src: url("./fonts/poppins/Poppins-Light.ttf") format("opentype");
}
/* Poppins Bold */
@font-face {
  font-family: "Poppins";
  font-weight: bold;
  src: url("./fonts/poppins/Poppins-Bold.ttf") format("opentype");
}
/* Poppins Regular */
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: url("./fonts/poppins/Poppins-Regular.ttf") format("opentype");
}
/* Poppins Medium */
@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: url("./fonts/poppins/Poppins-Medium.ttf") format("opentype");
}

/* Coco Gothic Light*/
@font-face {
  font-family: "Coco Gothic";
  font-weight: lighter;
  src: url("./fonts/coco_gothic/CocoGothic-Light_trial.ttf") format("opentype");
}
/* Coco Gothic Bold*/
@font-face {
  font-family: "Coco Gothic";
  font-weight: bold;
  src: url("./fonts/coco_gothic/CocoGothic-Bold_trial.ttf") format("opentype");
}

/* Coco Gothic Bold*/
@font-face {
  font-family: "Quicksand";
  font-weight: bold;
  src: url("./fonts/coco_gothic/CocoGothic-Bold_trial.ttf") format("opentype");
}

/* NoirPro */
@font-face {
  font-family: "NoirPro";
  src: url("./fonts/noir-pro/NoirPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "NoirPro";
  src: url("./fonts/noir-pro/NoirPro-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
