:root {
  --bg-primary: linear-gradient(135deg, #492cdd 0%, #ad38e2 100%);
  --bg-dark-mode: #212121;
  --border-dark-mode: #4d4c4c;
  --bg-dark-mode-content: #0f0f0f;
  --bg-global-button: #767676;
  --bg-global-table: #141414;
  --bg-white: #f8f8f8;
  --bg-remove: #ff5252;
  --color-white: #fff;
  --color-dark: rgba(0, 0, 0, 0.88);
  --bg-global-wrapper: #141414;
  --bg-global-modal-dark: #2c2c2c; /* color de la modal */
  --color-input: #43ec15;
  --box-shadow-white: rgb(18 38 63 / 41%);
  --box-shadow-dark: #112f03;
  --ds-text: #172b4d;
  --ds-bg: #091e420f;
  /*  */
  --blue: #0dacfb;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #ff5252;
  --orange: #fa8c15;
  --yellow: #faad14;
  --orange-light: #ffba57;
  --green: #9ccc65;
  --teal: rgb(16 127 97);
  --cyan: #00acc1;
  --white: #fff;
  --gray: #707f8f;
  --gray-light: #e5ecee;
  --blue-dark: #172533;

  --gray-dark-1: #343a40;
  --gray-dark: #262626;

  --icon: #492cdd;
  --primary-button: #1d37a2;
  --secondary: hsla(0, 0%, 100%, 0.75);
  --success: #9ccc65;
  --info: #00acc1;
  --warning: #ffba57;
  --danger: #ff5252;
  --light: #f8f9fa;
  --dark: #343a40;
  /* colors bottons actions */
  --bg-color-active: rgb(52, 195, 143);
  --bg-color-inactive: #ef6a6a;
  --color-black-linkedin: rgb(0 0 0 / 0.75);

  /* colors */
  --bg-birthday: #ff4d4f;
  --color-text-home: #283742;
  --color-body-description: rgba(40, 55, 66, 0.95);
  --bg-color-purple: #a855f7;
  --border-color-purple: #7e22ce;
  --text-color-purple: #faf5ff;

  --bg-color-pink: #be185d;
  --border-color-pink: #ec4899;
  --text-color-pink: #fdf2f8;

  --bg-color-yellow: #eab308;
  --border-color-yellow: #ca8a04;
  --text-color-yellow: #fefce8;

  --text-color-grey: #5e6c84;
  --text-color-blue-dark: #172b4d;

  --board-color-blue: #0079bf;
  --board-color-green: #52dd23;
  --board-color-purple: #89609e;
  --board-color-pink: #cd5a91;
  --board-color-orange-light: #d29034;
  --board-color-green-light: #4bbf6b;
  --board-color-blue-light: #00aecc;
  --board-color-grey-light: #838c91;

  --color-customization-yellow-background: #f8df72;
  --color-customization-yellow-foreground: #1e1f21;
  --color-customization-yellow-orange-text-deprecated: #1e1f21;
  --color-background-hover: rgba(55, 23, 23, 0.03);
  --color-text-weak: #6d6e6f;
  --color-text: #44546f;
  --color-button-link: #172b4d;
  --color1: #0dacfb;
  --color2: rgba(29, 199, 120, 1);
  --color3: rgba(132, 188, 156, 1);
  --color4: rgba(44, 165, 141, 1);
  --color5: rgba(0, 16, 33, 1);

  /* Colores principales */
  --color-gray-light: #e5ecee;
  --color-gray: #5f5f5f;
  --color-gray-dark: #464646;
  --color-green-light: #d7fbeb;
  --color-primary-blue: #1d37a2;
  --color-primary: var(--primary);
  --color-primary-button: var(--primary-button);
  --color-secundary: var(--secondary);
  --color-blue-dark: #172b4d;
  --color-yellow: rgba(237, 240, 96, 1);
  --color-orange: rgba(241, 162, 8, 1);
  --color-green: rgba(29, 199, 120, 1);
  --color-blue-ligth-1: var(--color2);
  --color-blue-dark-1: var(--color5);

  /* colors */
  --text-red: #ff5252;
  /* bacgrounds */
  --text-inherit: inherit;
  --text-current: currentColor;
  --text-transparent: transparent;
  --text-black: rgb(0 0 0);
  --text-white: rgb(255 255 255);
  --text-slate-50: rgb(248 250 252);
  --text-slate-100: rgb(241 245 249);
  --text-slate-200: rgb(226 232 240);
  --text-slate-300: rgb(203 213 225);
  --text-slate-400: rgb(148 163 184);
  --text-slate-500: rgb(100 116 139);
  --text-slate-600: rgb(71 85 105);
  --text-slate-700: rgb(51 65 85);
  --text-slate-800: rgb(30 41 59);
  --text-slate-900: rgb(15 23 42);
  --text-gray-50: rgb(249 250 251);
  --text-gray-100: rgb(243 244 246);
  --text-gray-200: rgb(229 231 235);
  --text-gray-300: rgb(209 213 219);
  --text-gray-400: rgb(156 163 175);
  --text-gray-500: rgb(107 114 128);
  --text-gray-600: rgb(75 85 99);
  --text-gray-700: rgb(55 65 81);
  --text-gray-800: rgb(31 41 55);
  --text-gray-900: rgb(17 24 39);
  --text-zinc-50: rgb(250 250 250);
  --text-zinc-100: rgb(244 244 245);
  --text-zinc-200: rgb(228 228 231);
  --text-zinc-300: rgb(212 212 216);
  --text-zinc-400: rgb(161 161 170);
  --text-zinc-500: rgb(113 113 122);
  --text-zinc-600: rgb(82 82 91);
  --text-zinc-700: rgb(63 63 70);
  --text-zinc-800: rgb(39 39 42);
  --text-zinc-900: rgb(24 24 27);
  --text-neutral-50: rgb(250 250 250);
  --text-neutral-100: rgb(245 245 245);
  --text-neutral-200: rgb(229 229 229);
  --text-neutral-300: rgb(212 212 212);
  --text-neutral-400: rgb(163 163 163);
  --text-neutral-500: rgb(115 115 115);
  --text-neutral-600: rgb(82 82 82);
  --text-neutral-700: rgb(64 64 64);
  --text-neutral-800: rgb(38 38 38);
  --text-neutral-900: rgb(23 23 23);
  --text-stone-50: rgb(250 250 249);
  --text-stone-100: rgb(245 245 244);
  --text-stone-200: rgb(231 229 228);
  --text-stone-300: rgb(214 211 209);
  --text-stone-400: rgb(168 162 158);
  --text-stone-500: rgb(120 113 108);
  --text-stone-600: rgb(87 83 78);
  --text-stone-700: rgb(68 64 60);
  --text-stone-800: rgb(41 37 36);
  --text-stone-900: rgb(28 25 23);
  --text-red-50: rgb(254 242 242);
  --text-red-100: rgb(254 226 226);
  --text-red-200: rgb(254 202 202);
  --text-red-300: rgb(252 165 165);
  --text-red-400: rgb(248 113 113);
  --text-red-500: rgb(239 68 68);
  --text-red-600: rgb(220 38 38);
  --text-red-700: rgb(185 28 28);
  --text-red-800: rgb(153 27 27);
  --text-red-900: rgb(127 29 29);
  --text-orange-50: rgb(255 247 237);
  --text-orange-100: rgb(255 237 213);
  --text-orange-200: rgb(254 215 170);
  --text-orange-300: rgb(253 186 116);
  --text-orange-400: rgb(251 146 60);
  --text-orange-500: rgb(249 115 22);
  --text-orange-600: rgb(234 88 12);
  --text-orange-700: rgb(194 65 12);
  --text-orange-800: rgb(154 52 18);
  --text-orange-900: rgb(124 45 18);
  --text-amber-50: rgb(255 251 235);
  --text-amber-100: rgb(254 243 199);
  --text-amber-200: rgb(253 230 138);
  --text-amber-300: rgb(252 211 77);
  --text-amber-400: rgb(251 191 36);
  --text-amber-500: rgb(245 158 11);
  --text-amber-600: rgb(217 119 6);
  --text-amber-700: rgb(180 83 9);
  --text-amber-800: rgb(146 64 14);
  --text-amber-900: rgb(120 53 15);
  --text-yellow-50: rgb(254 252 232);
  --text-yellow-100: rgb(254 249 195);
  --text-yellow-200: rgb(254 240 138);
  --text-yellow-300: rgb(253 224 71);
  --text-yellow-400: rgb(250 204 21);
  --text-yellow-500: rgb(234 179 8);
  --text-yellow-600: rgb(202 138 4);
  --text-yellow-700: rgb(161 98 7);
  --text-yellow-800: rgb(133 77 14);
  --text-yellow-900: rgb(113 63 18);
  --text-lime-50: rgb(247 254 231);
  --text-lime-100: rgb(236 252 203);
  --text-lime-200: rgb(217 249 157);
  --text-lime-300: rgb(190 242 100);
  --text-lime-400: rgb(163 230 53);
  --text-lime-500: rgb(132 204 22);
  --text-lime-600: rgb(101 163 13);
  --text-lime-700: rgb(77 124 15);
  --text-lime-800: rgb(63 98 18);
  --text-lime-900: rgb(54 83 20);
  --text-green-50: rgb(240 253 244);
  --text-green-100: rgb(220 252 231);
  --text-green-200: rgb(187 247 208);
  --text-green-300: rgb(134 239 172);
  --text-green-400: rgb(74 222 128);
  --text-green-500: rgb(34 197 94);
  --text-green-600: rgb(22 163 74);
  --text-green-700: rgb(21 128 61);
  --text-green-800: rgb(22 101 52);
  --text-green-900: rgb(20 83 45);
  --text-emerald-50: rgb(236 253 245);
  --text-emerald-100: rgb(209 250 229);
  --text-emerald-200: rgb(167 243 208);
  --text-emerald-300: rgb(110 231 183);
  --text-emerald-400: rgb(52 211 153);
  --text-emerald-500: rgb(16 185 129);
  --text-emerald-600: rgb(5 150 105);
  --text-emerald-700: rgb(4 120 87);
  --text-emerald-800: rgb(6 95 70);
  --text-emerald-900: rgb(6 78 59);
  --text-teal-50: rgb(240 253 250);
  --text-teal-100: rgb(204 251 241);
  --text-teal-200: rgb(153 246 228);
  --text-teal-300: rgb(94 234 212);
  --text-teal-400: rgb(45 212 191);
  --text-teal-500: rgb(20 184 166);
  --text-teal-600: rgb(13 148 136);
  --text-teal-700: rgb(15 118 110);
  --text-teal-800: rgb(17 94 89);
  --text-teal-900: rgb(19 78 74);
  --text-cyan-50: rgb(236 254 255);
  --text-cyan-100: rgb(207 250 254);
  --text-cyan-200: rgb(165 243 252);
  --text-cyan-300: rgb(103 232 249);
  --text-cyan-400: rgb(34 211 238);
  --text-cyan-500: rgb(6 182 212);
  --text-cyan-600: rgb(8 145 178);
  --text-cyan-700: rgb(14 116 144);
  --text-cyan-800: rgb(21 94 117);
  --text-cyan-900: rgb(22 78 99);
  --text-sky-50: rgb(240 249 255);
  --text-sky-100: rgb(224 242 254);
  --text-sky-200: rgb(186 230 253);
  --text-sky-300: rgb(125 211 252);
  --text-sky-400: rgb(56 189 248);
  --text-sky-500: rgb(14 165 233);
  --text-sky-600: rgb(2 132 199);
  --text-sky-700: rgb(3 105 161);
  --text-sky-800: rgb(7 89 133);
  --text-sky-900: rgb(12 74 110);
  --text-blue-50: rgb(239 246 255);
  --text-blue-100: rgb(219 234 254);
  --text-blue-200: rgb(191 219 254);
  --text-blue-300: rgb(147 197 253);
  --text-blue-400: rgb(96 165 250);
  --text-blue-500: rgb(59 130 246);
  --text-blue-600: rgb(37 99 235);
  --text-blue-700: rgb(29 78 216);
  --text-blue-800: rgb(30 64 175);
  --text-blue-900: rgb(30 58 138);
  --text-indigo-50: rgb(238 242 255);
  --text-indigo-100: rgb(224 231 255);
  --text-indigo-200: rgb(199 210 254);
  --text-indigo-300: rgb(165 180 252);
  --text-indigo-400: rgb(129 140 248);
  --text-indigo-500: rgb(99 102 241);
  --text-indigo-600: rgb(79 70 229);
  --text-indigo-700: rgb(67 56 202);
  --text-indigo-800: rgb(55 48 163);
  --text-indigo-900: rgb(49 46 129);
  --text-violet-50: rgb(245 243 255);
  --text-violet-100: rgb(237 233 254);
  --text-violet-200: rgb(221 214 254);
  --text-violet-300: rgb(196 181 253);
  --text-violet-400: rgb(167 139 250);
  --text-violet-500: rgb(139 92 246);
  --text-violet-600: rgb(124 58 237);
  --text-violet-700: rgb(109 40 217);
  --text-violet-800: rgb(91 33 182);
  --text-violet-900: rgb(76 29 149);
  --text-purple-50: rgb(250 245 255);
  --text-purple-100: rgb(243 232 255);
  --text-purple-200: rgb(233 213 255);
  --text-purple-300: rgb(216 180 254);
  --text-purple-400: rgb(192 132 252);
  --text-purple-500: rgb(168 85 247);
  --text-purple-600: rgb(147 51 234);
  --text-purple-700: rgb(126 34 206);
  --text-purple-800: rgb(107 33 168);
  --text-purple-900: rgb(88 28 135);
  --text-fuchsia-50: rgb(253 244 255);
  --text-fuchsia-100: rgb(250 232 255);
  --text-fuchsia-200: rgb(245 208 254);
  --text-fuchsia-300: rgb(240 171 252);
  --text-fuchsia-400: rgb(232 121 249);
  --text-fuchsia-500: rgb(217 70 239);
  --text-fuchsia-600: rgb(192 38 211);
  --text-fuchsia-700: rgb(162 28 175);
  --text-fuchsia-800: rgb(134 25 143);
  --text-fuchsia-900: rgb(112 26 117);
  --text-pink-50: rgb(253 242 248);
  --text-pink-100: rgb(252 231 243);
  --text-pink-200: rgb(251 207 232);
  --text-pink-300: rgb(249 168 212);
  --text-pink-400: rgb(244 114 182);
  --text-pink-500: rgb(236 72 153);
  --text-pink-600: rgb(219 39 119);
  --text-pink-700: rgb(190 24 93);
  --text-pink-800: rgb(157 23 77);
  --text-pink-900: rgb(131 24 67);
  --text-rose-50: rgb(255 241 242);
  --text-rose-100: rgb(255 228 230);
  --text-rose-200: rgb(254 205 211);
  --text-rose-300: rgb(253 164 175);
  --text-rose-400: rgb(251 113 133);
  --text-rose-500: rgb(244 63 94);
  --text-rose-600: rgb(225 29 72);
  --text-rose-700: rgb(190 18 60);
  --text-rose-800: rgb(159 18 57);
  --text-rose-900: rgb(136 19 55);

  /* Bacgrounds */
  --bg-inherit: inherit;
  --bg-current: currentColor;
  --bg-transparent: transparent;
  --bg-black: rgb(0 0 0);
  --bg-white: rgb(255 255 255);
  --bg-slate-50: rgb(248 250 252);
  --bg-slate-100: rgb(241 245 249);
  --bg-slate-200: rgb(226 232 240);
  --bg-slate-300: rgb(203 213 225);
  --bg-slate-400: rgb(148 163 184);
  --bg-slate-500: rgb(100 116 139);
  --bg-slate-600: rgb(71 85 105);
  --bg-slate-700: rgb(51 65 85);
  --bg-slate-800: rgb(30 41 59);
  --bg-slate-900: rgb(15 23 42);
  --bg-gray-50: rgb(249 250 251);
  --bg-gray-100: rgb(243 244 246);
  --bg-gray-200: rgb(229 231 235);
  --bg-gray-300: rgb(209 213 219);
  --bg-gray-400: rgb(156 163 175);
  --bg-gray-500: rgb(107 114 128);
  --bg-gray-600: rgb(75 85 99);
  --bg-gray-700: rgb(55 65 81);
  --bg-gray-800: rgb(31 41 55);
  --bg-gray-900: rgb(17 24 39);
  --bg-zinc-50: rgb(250 250 250);
  --bg-zinc-100: rgb(244 244 245);
  --bg-zinc-200: rgb(228 228 231);
  --bg-zinc-300: rgb(212 212 216);
  --bg-zinc-400: rgb(161 161 170);
  --bg-zinc-500: rgb(113 113 122);
  --bg-zinc-600: rgb(82 82 91);
  --bg-zinc-700: rgb(63 63 70);
  --bg-zinc-800: rgb(39 39 42);
  --bg-zinc-900: rgb(24 24 27);
  --bg-neutral-50: rgb(250 250 250);
  --bg-neutral-100: rgb(245 245 245);
  --bg-neutral-200: rgb(229 229 229);
  --bg-neutral-300: rgb(212 212 212);
  --bg-neutral-400: rgb(163 163 163);
  --bg-neutral-500: rgb(115 115 115);
  --bg-neutral-600: rgb(82 82 82);
  --bg-neutral-700: rgb(64 64 64);
  --bg-neutral-800: rgb(38 38 38);
  --bg-neutral-900: rgb(23 23 23);
  --bg-stone-50: rgb(250 250 249);
  --bg-stone-100: rgb(245 245 244);
  --bg-stone-200: rgb(231 229 228);
  --bg-stone-300: rgb(214 211 209);
  --bg-stone-400: rgb(168 162 158);
  --bg-stone-500: rgb(120 113 108);
  --bg-stone-600: rgb(87 83 78);
  --bg-stone-700: rgb(68 64 60);
  --bg-stone-800: rgb(41 37 36);
  --bg-stone-900: rgb(28 25 23);
  --bg-red-50: rgb(254 242 242);
  --bg-red-100: rgb(254 226 226);
  --bg-red-200: rgb(254 202 202);
  --bg-red-300: rgb(252 165 165);
  --bg-red-400: rgb(248 113 113);
  --bg-red-500: rgb(239 68 68);
  --bg-red-600: rgb(220 38 38);
  --bg-red-700: rgb(185 28 28);
  --bg-red-800: rgb(153 27 27);
  --bg-red-900: rgb(127 29 29);
  --bg-orange-50: rgb(255 247 237);
  --bg-orange-100: rgb(255 237 213);
  --bg-orange-200: rgb(254 215 170);
  --bg-orange-300: rgb(253 186 116);
  --bg-orange-400: rgb(251 146 60);
  --bg-orange-500: rgb(249 115 22);
  --bg-orange-600: rgb(234 88 12);
  --bg-orange-700: rgb(194 65 12);
  --bg-orange-800: rgb(154 52 18);
  --bg-orange-900: rgb(124 45 18);
  --bg-amber-50: rgb(255 251 235);
  --bg-amber-100: rgb(254 243 199);
  --bg-amber-200: rgb(253 230 138);
  --bg-amber-300: rgb(252 211 77);
  --bg-amber-400: rgb(251 191 36);
  --bg-amber-500: rgb(245 158 11);
  --bg-amber-600: rgb(217 119 6);
  --bg-amber-700: rgb(180 83 9);
  --bg-amber-800: rgb(146 64 14);
  --bg-amber-900: rgb(120 53 15);
  --bg-yellow-50: rgb(254 252 232);
  --bg-yellow-100: rgb(254 249 195);
  --bg-yellow-200: rgb(254 240 138);
  --bg-yellow-300: rgb(253 224 71);
  --bg-yellow-400: rgb(250 204 21);
  --bg-yellow-500: rgb(234 179 8);
  --bg-yellow-600: rgb(202 138 4);
  --bg-yellow-700: rgb(161 98 7);
  --bg-yellow-800: rgb(133 77 14);
  --bg-yellow-900: rgb(113 63 18);
  --bg-lime-50: rgb(247 254 231);
  --bg-lime-100: rgb(236 252 203);
  --bg-lime-200: rgb(217 249 157);
  --bg-lime-300: rgb(190 242 100);
  --bg-lime-400: rgb(163 230 53);
  --bg-lime-500: rgb(132 204 22);
  --bg-lime-600: rgb(101 163 13);
  --bg-lime-700: rgb(77 124 15);
  --bg-lime-800: rgb(63 98 18);
  --bg-lime-900: rgb(54 83 20);
  --bg-green-50: rgb(240 253 244);
  --bg-green-100: rgb(220 252 231);
  --bg-green-200: rgb(187 247 208);
  --bg-green-300: rgb(134 239 172);
  --bg-green-400: rgb(74 222 128);
  --bg-green-500: rgb(34 197 94);
  --bg-green-600: rgb(22 163 74);
  --bg-green-700: rgb(21 128 61);
  --bg-green-800: rgb(22 101 52);
  --bg-green-900: rgb(20 83 45);
  --bg-emerald-50: rgb(236 253 245);
  --bg-emerald-100: rgb(209 250 229);
  --bg-emerald-200: rgb(167 243 208);
  --bg-emerald-300: rgb(110 231 183);
  --bg-emerald-400: rgb(52 211 153);
  --bg-emerald-500: rgb(16 185 129);
  --bg-emerald-600: rgb(5 150 105);
  --bg-emerald-700: rgb(4 120 87);
  --bg-emerald-800: rgb(6 95 70);
  --bg-emerald-900: rgb(6 78 59);
  --bg-teal-50: rgb(240 253 250);
  --bg-teal-100: rgb(204 251 241);
  --bg-teal-200: rgb(153 246 228);
  --bg-teal-300: rgb(94 234 212);
  --bg-teal-400: rgb(45 212 191);
  --bg-teal-500: rgb(20 184 166);
  --bg-teal-600: rgb(13 148 136);
  --bg-teal-700: rgb(15 118 110);
  --bg-teal-800: rgb(17 94 89);
  --bg-teal-900: rgb(19 78 74);
  --bg-cyan-50: rgb(236 254 255);
  --bg-cyan-100: rgb(207 250 254);
  --bg-cyan-200: rgb(165 243 252);
  --bg-cyan-300: rgb(103 232 249);
  --bg-cyan-400: rgb(34 211 238);
  --bg-cyan-500: rgb(6 182 212);
  --bg-cyan-600: rgb(8 145 178);
  --bg-cyan-700: rgb(14 116 144);
  --bg-cyan-800: rgb(21 94 117);
  --bg-cyan-900: rgb(22 78 99);
  --bg-sky-50: rgb(240 249 255);
  --bg-sky-100: rgb(224 242 254);
  --bg-sky-200: rgb(186 230 253);
  --bg-sky-300: rgb(125 211 252);
  --bg-sky-400: rgb(56 189 248);
  --bg-sky-500: rgb(14 165 233);
  --bg-sky-600: rgb(2 132 199);
  --bg-sky-700: rgb(3 105 161);
  --bg-sky-800: rgb(7 89 133);
  --bg-sky-900: rgb(12 74 110);
  --bg-blue-50: rgb(239 246 255);
  --bg-blue-100: rgb(219 234 254);
  --bg-blue-200: rgb(191 219 254);
  --bg-blue-300: rgb(147 197 253);
  --bg-blue-400: rgb(96 165 250);
  --bg-blue-500: rgb(59 130 246);
  --bg-blue-600: rgb(37 99 235);
  --bg-blue-700: rgb(29 78 216);
  --bg-blue-800: rgb(30 64 175);
  --bg-blue-900: rgb(30 58 138);
  --bg-indigo-50: rgb(238 242 255);
  --bg-indigo-100: rgb(224 231 255);
  --bg-indigo-200: rgb(199 210 254);
  --bg-indigo-300: rgb(165 180 252);
  --bg-indigo-400: rgb(129 140 248);
  --bg-indigo-500: rgb(99 102 241);
  --bg-indigo-600: rgb(79 70 229);
  --bg-indigo-700: rgb(67 56 202);
  --bg-indigo-800: rgb(55 48 163);
  --bg-indigo-900: rgb(49 46 129);
  --bg-violet-50: rgb(245 243 255);
  --bg-violet-100: rgb(237 233 254);
  --bg-violet-200: rgb(221 214 254);
  --bg-violet-300: rgb(196 181 253);
  --bg-violet-400: rgb(167 139 250);
  --bg-violet-500: rgb(139 92 246);
  --bg-violet-600: rgb(124 58 237);
  --bg-violet-700: rgb(109 40 217);
  --bg-violet-800: rgb(91 33 182);
  --bg-violet-900: rgb(76 29 149);
  --bg-purple-50: rgb(250 245 255);
  --bg-purple-100: rgb(243 232 255);
  --bg-purple-200: rgb(233 213 255);
  --bg-purple-300: rgb(216 180 254);
  --bg-purple-400: rgb(192 132 252);
  --bg-purple-500: rgb(168 85 247);
  --bg-purple-600: rgb(147 51 234);
  --bg-purple-700: rgb(126 34 206);
  --bg-purple-800: rgb(107 33 168);
  --bg-purple-900: rgb(88 28 135);
  --bg-fuchsia-50: rgb(253 244 255);
  --bg-fuchsia-100: rgb(250 232 255);
  --bg-fuchsia-200: rgb(245 208 254);
  --bg-fuchsia-300: rgb(240 171 252);
  --bg-fuchsia-400: rgb(232 121 249);
  --bg-fuchsia-500: rgb(217 70 239);
  --bg-fuchsia-600: rgb(192 38 211);
  --bg-fuchsia-700: rgb(162 28 175);
  --bg-fuchsia-800: rgb(134 25 143);
  --bg-fuchsia-900: rgb(112 26 117);
  --bg-pink-50: rgb(253 242 248);
  --bg-pink-100: rgb(252 231 243);
  --bg-pink-200: rgb(251 207 232);
  --bg-pink-300: rgb(249 168 212);
  --bg-pink-400: rgb(244 114 182);
  --bg-pink-500: rgb(236 72 153);
  --bg-pink-600: rgb(219 39 119);
  --bg-pink-700: rgb(190 24 93);
  --bg-pink-800: rgb(157 23 77);
  --bg-pink-900: rgb(131 24 67);
  --bg-rose-50: rgb(255 241 242);
  --bg-rose-100: rgb(255 228 230);
  --bg-rose-200: rgb(254 205 211);
  --bg-rose-300: rgb(253 164 175);
  --bg-rose-400: rgb(251 113 133);
  --bg-rose-500: rgb(244 63 94);
  --bg-rose-600: rgb(225 29 72);
  --bg-rose-700: rgb(190 18 60);
  --bg-rose-800: rgb(159 18 57);
  --bg-rose-900: rgb(136 19 55);

  --bg-slate: rgba(55, 23, 23, 0.03);
}
